@import url("https://fonts.googleapis.com/css?family=Titillium+Web&display=swap");

@import "~bootswatch/dist/darkly/variables";

// Override default variables before the import
$font-family-base: "Titillium Web", sans-serif;

$primary: rebeccapurple;
$secondary: orangered;

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
}

$nav-link-padding-x: 1rem;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";

.list-group-item-warning.list-group-item-action {
  color: #7e5109;
}

.list-group-item-info.list-group-item-action {
  color: #1b4f72;
}
