.user-profile-avatar {
  .user-avatar {
    display: block;
    width: initial;
    height: initial;

    .hat {
      font-size: 72px;
    }
  }
}
