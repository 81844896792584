.user-avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  .display-picture {
    width: 100%;
    height: 100%;
  }

  .hat {
    position: absolute;
    margin-top: -30%;
  }

  span.hat {
    width: 100%;
    text-align: center;
  }

  div.hat {
    img {
      width: 90%;
      text-align: center;
    }
  }
}
