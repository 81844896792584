.scratchcard {
  width: 350px;
  position: fixed;
  top: calc(50% - 220px);
  left: calc(50% - 175px);
  margin: 0 auto;
  background-color: #feffff;
  color: black;
  padding-bottom: 30px;
  font-family: monospace;
  font-weight: bold;
  border-radius: 5px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  z-index: 1;
}

.scratchcard-row {
  padding-top: 30px;
  text-align: center;
}

.scratchcard-value {
  width: 70px;
  font-size: 15pt;
  display: inline-block;
  margin-right: 15px;
  margin-left: 15px;
  padding: 15px;
  border-radius: 5px;
  background-color: lightgray;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  cursor: pointer;
}

.covered {
  background-color: #ffef3a;
}

.scratchcard-title {
  margin-top: 25px;
  margin-left: 35px;
  font-size: 20px;
  color: black;
}

.scratchcard-company {
  margin-top: 20px;
  margin-bottom: -15px;
  font-size: 10px;
  margin-right: 35px;
  text-align: right;
}

.scratchcard-claim {
  position: relative;
  margin-left: 28%;
  top: 390px;
}

.scratchcard-close {
  text-align: center;
  display: inline;
  float: right;
  margin: 13px;
  font-sie: 20pt;
  font-size: 12pt;
  border-style: solid;
  border-width: 2px;
  width: 24px;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}

.winnings {
  display: inline-block;
}
