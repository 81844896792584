.portfolio-total {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}
.portfolio-title {
  display: inline-block;
}
.dividends-info {
  display: inline-block;
  margin-right: 50px;
}
.dividends-info-wrapper {
  width: 100%;
}
.trade-add-form {
  margin-right: 10px;
  display: inline-block;
  margin-top: 20px;
}
.trade-add-button {
  margin-top: 0px;
}
.trade-form-number {
  display: inline-block;
}
.trade-form-top {
  margin-top: 0px;
}
.positive {
  color: green;
}
.negative {
  color: red;
}
.total-owned {
  float: right;
}
.share-tax-display {
  display: inline-block;
  margin-left: 10px;
  font-size: 10pt;
}
