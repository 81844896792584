@import "../../index.scss";

.spinner {
  $translate-size: 50px;
  $beer-size: 30px;
  $scale-amount: 0.5;

  width: $translate-size + $beer-size;
  height: $translate-size + $beer-size;
  position: relative;

  .beer1,
  .beer2 {
    width: $beer-size;
    height: $beer-size;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
  }

  .beer1 {
    color: $primary;
  }

  .beer2 {
    color: $secondary;
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  @-webkit-keyframes sk-cubemove {
    25% {
      -webkit-transform: translateX($translate-size) rotate(-90deg)
        scale($scale-amount);
    }
    50% {
      -webkit-transform: translateX($translate-size) translateY($translate-size)
        rotate(-180deg);
    }
    75% {
      -webkit-transform: translateX(0px) translateY($translate-size)
        rotate(-270deg) scale($scale-amount);
    }
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }

  @keyframes sk-cubemove {
    25% {
      transform: translateX($translate-size) rotate(-90deg) scale($scale-amount);
      -webkit-transform: translateX($translate-size) rotate(-90deg)
        scale($scale-amount);
    }
    50% {
      transform: translateX($translate-size) translateY($translate-size)
        rotate(-179deg);
      -webkit-transform: translateX($translate-size) translateY($translate-size)
        rotate(-179deg);
    }
    50.1% {
      transform: translateX($translate-size) translateY($translate-size)
        rotate(-180deg);
      -webkit-transform: translateX($translate-size) translateY($translate-size)
        rotate(-180deg);
    }
    75% {
      transform: translateX(0px) translateY($translate-size) rotate(-270deg)
        scale($scale-amount);
      -webkit-transform: translateX(0px) translateY($translate-size)
        rotate(-270deg) scale($scale-amount);
    }
    100% {
      transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
  }
}
