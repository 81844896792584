@import "../../index.scss";

@keyframes doublecoin {
  0% {
    border-color: $success;
  }
  50% {
    border-color: $primary;
  }
  100% {
    border-color: $success;
  }
}

@keyframes doublecoin-text {
  0% {
    color: initial;
  }
  50% {
    color: $success;
  }
  100% {
    color: initial;
  }
}

.container .sessions-card {
  border-color: $success;

  &.shareholder {
    border-color: $info;
  }

  &.bonus {
    border-color: $secondary;
  }

  &.doubleCoin {
    border-color: $primary;
    animation-name: doublecoin;
    animation-iteration-count: infinite;
  }

  .doublecoin-text {
    color: $primary;
    display: inline-block;
    animation-name: doublecoin-text;
    animation-iteration-count: infinite;
  }
}
