.help-section-header {
  font-weight: bold;
  margin-bottom: 15px;
}
.help-icon {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
}
.help-icon-description {
  display: inline-block;
}
