@import "../../index.scss";

$home-color: #214cb8;
$away-color: #d6181f;

// Request a theme color level
@function theme-color-generator($color, $level: 0) {
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}

@keyframes fall {
  0% {
    transform-origin: bottom;
    animation-timing-function: ease-in;
  }

  20%,
  40% {
    transform: translateY(27px);
    transform-origin: bottom;
    animation-timing-function: ease-in;
  }

  40%,
  100% {
    transform: rotateX(90deg);
    transform-origin: bottom;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  to {
    transform: translateY(60px);
    transform-origin: bottom;
    opacity: 0;
  }
}

.match {
  transition: all 2000ms;

  &.homeWinning {
    background-color: theme-color-generator($home-color, -10);
    color: theme-color-generator($home-color, 6);
  }

  &.homeWinConfirmed {
    color: $home-color;
  }

  &.awayWinning {
    background-color: theme-color-generator($away-color, -10);
    color: theme-color-generator($away-color, 6);
  }

  &.awayWinConfirmed {
    color: $away-color;
  }

  .align-left.loser {
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-name: fall;
  }

  .winner {
    font-weight: bold;
    transition: all 300ms;
  }

  .align-right.loser {
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-name: fall;
  }

  .match-tracker {
    width: 100%;

    .btn {
      flex: 0;
      border: none;

      &:disabled {
        opacity: 1;
      }
    }

    .btn:first-child {
      background: $home-color;
    }

    .btn:last-child {
      background: $away-color;
    }

    .progress {
      background: $away-color;
      border-radius: 0;
      flex: 1;
      height: auto;

      .progress-bar {
        background: $home-color;
      }
    }
  }
}
