.nav-bar {
  display: inherit;
  margin-bottom: 15px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.nav-bar-title {
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
}
.nav-bar-option {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.nav-bar-option-wrapper {
  display: inline-block;
  padding-left: 10px;
  padding-top: 10px;
}
.nav-bar-selected {
  border-color: black;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}
.plus-link {
  font-weight: bold;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
