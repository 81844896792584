.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  border-bottom: solid;
  margin-top: 10px;
  margin-bottom: 15px;
}
.site-title {
  display: inline-block;
  font-size: 14pt;
  font-weight: bold;
}
.user-status {
  float: right;
  font-size: 11pt;
}
.page-title {
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 10px;
}
.logout-button,
.login-button {
  display: inline-block;
  cursor: pointer;
}
.footer {
  border-top: solid;
  margin-top: 10px;
  margin-bottom: 30px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mar-5 {
  margin-right: 5px;
}
.logout-button {
  text-decoration: underline;
  cursor: pointer;
}
.box {
  padding: 15px;
  margin-bottom: 15px;
}
.user-img {
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
}
.status-checked-in {
  color: green;
}
.display-name {
  min-width: 190px;
}
.table-or {
  margin-bottom: 0px;
}
.location-info {
  display: inline-block;
  margin-left: 15px;
}
.display-title {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 25px;
}
.display-choice {
  display: inline-block;
  width: 100px;
}
.time-output {
  width: 550px;
}
.display-name-text {
  cursor: pointer;
  color: black;
}
.champion {
  display: inline-block;
  margin-left: 8px;
}
.hide {
  display: none;
}
.mt-m10 {
  margin-top: -10px;
}
.balance-display {
  float: right;
  margin-right: 10px;
  margin-top: 15px;
}
.eu-country-count {
  font-size: 48px;
  text-align: center;
  margin-top: 100px;
}

/* items */
.image-spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
