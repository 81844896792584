.transfer-amount-input {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  width: 100px;
}
.transfer-button {
  margin-top: 10px;
}
.transfer-user {
  display: inline-block;
  margin-right: 10px;
}
.transfer-user-label {
  display: inline-block;
}
.transfer-value {
  display: inline-block;
  margin-left: 12px;
}
.transfer-text {
  display: inline-block;
  margin-right: 10px;
}
.playing {
  width: 56px;
}
.float-right {
  float: right;
}
.items-container {
  min-width: 500px;
}

.pub-coin {
  display: inline-block;
  white-space: nowrap;
  transition: color 300s, transform 200ms;
}

.pub-coin.increase {
  transform: scale(1.25);
}

.pub-coin.decrease {
  transform: scale(1.25);
}

.pub-coin.colours.increase {
  transition: color 0s, transform 200ms;
  color: #0f9d58;
}

.pub-coin.colours.decrease {
  transition: color 0s, transform 200ms;
  color: #d23f31;
}

.align-right {
  text-align: right;
}

.exchange-rate {
  text-align: right;
}
.exchange-rate-coin {
  display: inline-block;
}
.exchange-rate-equals {
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
}
.exchange-rate-info {
  display: inline-block;
  margin-right: 15px;
  font-weight: bold;
}
.exchange-rate-price {
  display: inline-block;
}
.pub-coin-desc {
  margin-right: 10px;
  display: inline-block;
}
.share-tax-display-coin {
  margin-left: 2px;
  display: inline-block;
}
