$c--background: #333;

$s--near: 10s;
$s--mid: ($s--near * 2);
$s--far: ($s--near * 3);

$ease--out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

%on-top {
  z-index: 10000;
  pointer-events: none;
}

.christmas-bg {
  z-index: -100;
  background-image: url("/images/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.winter-is-coming {
  @extend %on-top;
  overflow: hidden;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.snow {
  @extend %on-top;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: falling linear infinite both;
  transform: translate3D(0, -100%, 0);

  &--near {
    animation-duration: $s--near;
    background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-large-075d267ecbc42e3564c8ed43516dd557.png");
    background-size: contain;

    & + .snow--alt {
      animation-delay: ($s--near / 2);
    }
  }

  &--mid {
    animation-duration: $s--mid;
    background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-medium-0b8a5e0732315b68e1f54185be7a1ad9.png");
    background-size: contain;

    & + .snow--alt {
      animation-delay: ($s--mid / 2);
    }
  }

  &--far {
    animation-duration: $s--far;
    background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-small-1ecd03b1fce08c24e064ff8c0a72c519.png");
    background-size: contain;

    & + .snow--alt {
      animation-delay: ($s--far / 2);
    }
  }
}

@keyframes falling {
  0% {
    transform: translate3D(-7.5%, -100%, 0);
  }

  100% {
    transform: translate3D(7.5%, 100%, 0);
  }
}
