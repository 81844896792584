.map-container {
  height: 100%;
}
.info-window {
  color: black;
}
.info-header {
  font-weight: bold;
  margin-bottom: 5px;
}
.info-vicinity {
  margin-bottom: 8px;
}
.record-visit {
  margin-top: 8px;
}
