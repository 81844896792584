.status-setup {
  color: orange;
}
.status-voting {
  color: green;
}
.status-winner {
  color: black;
}
.non-form-button {
  width: 160px;
}
.rules {
  display: block;
  margin-bottom: 25px;
}
.cop-title {
  display: inline-block;
  margin-right: 30px;
}
.cop-right {
  float: right;
}
.winner-img {
  height: 30px;
  border-radius: 15px;
  margin-right: 5px;
  margin-left: 5px;
}
.winner-creator-name {
  display: inline-block;
}
.winner-name {
  font-size: 28pt;
  margin-bottom: 20px;
  margin-top: 20px;
}
.winner-container {
  text-align: center;
  margin-bottom: 35px;
}

.vote-container {
  text-align: center;
}
.vote-button {
  display: inline-block;
  margin-right: 10px;
}
.team-name {
  display: inline-block;
  margin-right: 10px;
}
.team-votes {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}
.home-team {
}
.away-team {
}
.cop-m-top {
  margin-top: 10px;
}
.team-votes-wrapper {
  text-align: center;
  min-width: 105px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
