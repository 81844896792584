.leaderboard .form-control {
  width: auto;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.leaderboard h5 {
  margin: 0;
}

.leaderboard-row {
  transition: background-color 1s, color 1s;

  &.list-group-item-success {
    .pub-coin.colours {
      transition: color 200ms;
    }
  }
}
